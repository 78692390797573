<template>
  <div class="terms-condition-detail">
    <v-container
      fluid
      :class="{
        'pointer-events-none': !getPermission(type + ':update'),
      }"
    >
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <h3 class="font-weight-700 custom-headline color-custom-blue">
            Terms &amp; Conditions
          </h3>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <div v-on:dblclick="updateTemplate()">
            <editor
              v-model="termsConditions"
              :disabled="formLoading || !updateDialog"
            />
          </div>
          <div
            v-if="updateDialog"
            class="text-right custom-border-top py-4 mt-4"
          >
            <v-btn
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              :loading="formLoading"
              :disabled="formLoading"
              v-on:click="updateTermCondition"
            >
              Save
            </v-btn>
            <v-btn
              :disabled="formLoading"
              class="custom-grey-border custom-bold-button"
              v-on:click="updateDialog = false"
            >
              Cancel
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { PATCH } from "@/core/services/store/request.module";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    canUpdate: {
      type: Boolean,
      default: true,
    },
    type: {
      required: true,
      type: String,
      default: "job",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.termsConditions = param.term_conditions;
      },
    },
  },
  data() {
    return {
      updateDialog: false,
      formLoading: false,
      termsConditions: null,
    };
  },
  mounted() {
    this.termsConditions = this.detail.term_conditions;
  },
  components: {
    editor: TinyMCE,
  },
  methods: {
    updateTemplate() {
      if (this.canUpdate && this.getPermission(this.type + ":update")) {
        this.updateDialog = true;
      }
    },
    patchDetail(parent, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (parent) {
          _this.$store
            .dispatch(PATCH, { url: this.type + "/" + parent, data })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject("Parent is required");
        }
      });
    },
    updateTermCondition() {
      const _this = this;
      if (_this.canUpdate && _this.getPermission(_this.type + ":update")) {
        _this.formLoading = true;
        _this
          .patchDetail(_this.detail.id, {
            term_conditions: _this.termsConditions,
          })
          .then(() => {
            _this.updateDialog = false;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
  },
};
</script>
