<template>
  <DetailTemplate
    :customClass="'invoice-detail detail-page'"
    v-if="getPermission('invoice:view')"
  >
    <template v-slot:header-title>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        width="400"
        height="28"
        type="text"
      >
      </v-skeleton-loader>
      <h1 class="form-title custom-nowrap-ellipsis text-capitalize">
        {{ detail.title }}
        <v-chip
          v-if="detail.status_text == 'Partially Paid'"
          class="ma-2 white--text"
          color="orange"
          label
        >
          {{ detail.status_text }}
        </v-chip>
        <v-chip
          v-if="detail.status_text == 'Paid'"
          class="ma-2 white--text"
          color="green lighten-1"
          label
        >
          {{ detail.status_text }}
        </v-chip>
        <v-chip
          v-if="detail.status_text == 'Unpaid'"
          class="ma-2 white--text"
          color="red lighten-1"
          label
        >
          {{ detail.status_text }}
        </v-chip>
        <v-chip
          v-if="detail.status == 4 || detail.status == 5"
          class="ma-2 white--text"
          color="cyan"
          label
        >
          {{ detail.invoice_status_text }}
        </v-chip>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-if="
          detail.status < 5 &&
          detail.pending_amount > 0 &&
          getPermission('payment:create')
        "
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="recordPaymentDialog = true"
      >
        <v-icon left>mdi-plus</v-icon> Payment
      </v-btn>
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 65vh; position: relative"
      >
        <v-container fluid>
          <v-row dense>
            <v-col cols="5" class="pb-0">
              <h3>
                <router-link
                  :to="
                    getDefaultRoute('customer.detail', {
                      params: {
                        id: customer.customer,
                      },
                    })
                  "
                  >{{ customer.display_name }}
                </router-link>
              </h3>
              <v-layout class="my-4">
                <v-flex md6 class="mr-2 custom-border-right">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Service Location</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${property.property_address}`"
                          >{{ property.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="property_contact_person.display_name">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ property_contact_person.display_name }}
                      </td>
                    </tr>
                    <tr v-if="property_contact_person.primary_phone">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ property_contact_person.primary_phone }}
                      </td>
                    </tr>
                    <tr v-if="property_contact_person.primary_email">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ property_contact_person.primary_email }}
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <v-flex md6 class="ml-2">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Billing Address</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${billing.property_address}`"
                          >{{ billing.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="billing_contact_person.display_name">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ billing_contact_person.display_name }}
                      </td>
                    </tr>
                    <tr v-if="billing_contact_person.primary_phone">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ billing_contact_person.primary_phone }}
                      </td>
                    </tr>
                    <tr v-if="billing_contact_person.primary_email">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ billing_contact_person.primary_email }}
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
              <v-container v-if="false" fluid>
                <v-list flat>
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                          class="py-1 max-content-width"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28">mdi-account</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="p-0">
                            <v-list-item-title
                              class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                            >
                              <router-link
                                :to="
                                  getDefaultRoute('customer.detail', {
                                    params: {
                                      id: customer.customer,
                                    },
                                  })
                                "
                                >{{ detail.attention }}</router-link
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            link
                            class="m-0 ml-4"
                            v-on:click="
                              $router.push(
                                getDefaultRoute('customer.detail', {
                                  params: {
                                    id: customer.customer,
                                  },
                                })
                              )
                            "
                          >
                            <v-icon class="color-custom-blue"
                              >mdi-link mdi-rotate-135</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <span>Attention</span>
                    </v-tooltip>
                  </template>
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                          class="py-1 max-content-width"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28">mdi-email</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="p-0">
                            <v-list-item-title
                              class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                            >
                              <a
                                :href="'mailto:' + contact_person.primary_email"
                                >{{ contact_person.primary_email }}</a
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <span>Contact Email</span>
                    </v-tooltip>
                  </template>
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                          class="py-1 max-content-width"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28">mdi-phone</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="p-0">
                            <v-list-item-title
                              class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                            >
                              <a
                                :href="'tel:' + contact_person.primary_phone"
                                >{{ contact_person.primary_phone }}</a
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <span>Contact Phone</span>
                    </v-tooltip>
                  </template>
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="py-1" v-bind="attrs" v-on="on">
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28"
                              >mdi-home-map-marker</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content
                            class="p-0"
                            v-if="property.property_address"
                          >
                            <v-list-item-title
                              class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                            >
                              <a
                                target="_blank"
                                :href="
                                  'https://maps.google.com/?q=' +
                                  property.property_address
                                "
                                >{{ property.property_address }}</a
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <span>Service Location</span>
                    </v-tooltip>
                  </template>
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="py-1" v-bind="attrs" v-on="on">
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28"
                              >mdi-home-currency-usd</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content class="p-0">
                            <v-list-item-title
                              class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                            >
                              <a
                                target="_blank"
                                :href="
                                  'https://maps.google.com/?q=' +
                                  billing.property_address
                                "
                                >{{ billing.property_address }}</a
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <span>Billing Address</span>
                    </v-tooltip>
                  </template>
                </v-list>
              </v-container>
            </v-col>
            <v-col cols="7" class="pb-0 gray-background pl-4">
              <v-container fluid>
                <v-row dense>
                  <v-col md="12">
                    <v-skeleton-loader
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      type="text"
                      v-if="pageLoading"
                    >
                    </v-skeleton-loader>
                    <p
                      v-else
                      class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
                      v-on:dblclick="updateField('title')"
                    >
                      <template v-if="updateDetailDialog.title">
                        <v-text-field
                          ref="title"
                          v-model.trim="updateDetail.title"
                          dense
                          filled
                          color="cyan"
                          label="Invoice Subject"
                          :rules="[
                            validateRules.minLength(
                              updateDetail.title,
                              'Invoice Subject',
                              1
                            ),
                            validateRules.maxLength(
                              updateDetail.title,
                              'Invoice Subject',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                          :loading="particuleFieldLoading"
                          :disabled="particuleFieldLoading"
                          @keydown.esc="onEscParticularField"
                          @keydown.enter="updateParticularField"
                        ></v-text-field>
                      </template>
                      <template v-else>
                        <span
                          :class="{
                            'custom-bottom-border': canUpdateInvoice,
                          }"
                          >{{ detail.title }}</span
                        >
                        <UpdateTooltip v-if="canUpdateInvoice"></UpdateTooltip>
                      </template>
                    </p>
                  </v-col>
                  <v-col md="12">
                    <template v-if="pageLoading">
                      <v-skeleton-loader
                        v-for="(d, i) in 4"
                        :key="i"
                        class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                        :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                        type="text"
                      >
                      </v-skeleton-loader>
                    </template>
                    <template v-else>
                      <table class="width-100">
                        <tr>
                          <td class="font-size-18 pt-3 pb-1" width="200">
                            Invoice #
                          </td>
                          <td class="font-weight-600 font-size-18 pt-3 pb-1">
                            {{ detail.barcode }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-size-18 py-1" width="200">
                            Reference #
                          </td>
                          <td
                            class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                            v-on:dblclick="updateField('reference')"
                          >
                            <template v-if="updateDetailDialog.reference">
                              <v-text-field
                                ref="reference"
                                v-model.trim="updateDetail.reference"
                                dense
                                filled
                                color="cyan"
                                label="Reference #"
                                :rules="[
                                  validateRules.minLength(
                                    updateDetail.reference,
                                    'Reference',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    updateDetail.reference,
                                    'Reference',
                                    100
                                  ),
                                ]"
                                solo
                                flat
                                hide-details
                                :loading="particuleFieldLoading"
                                :disabled="particuleFieldLoading"
                                @keydown.esc="onEscParticularField"
                                @keydown.enter="updateParticularField"
                              ></v-text-field>
                            </template>
                            <template v-else>
                              <span
                                :class="{
                                  'custom-bottom-border': canUpdateInvoice,
                                }"
                              >
                                <template v-if="detail.reference">{{
                                  detail.reference
                                }}</template>
                                <template v-else
                                  ><em class="text--secondary font-weight-500"
                                    >No Reference</em
                                  ></template
                                >
                              </span>
                              <UpdateTooltip
                                v-if="canUpdateInvoice"
                              ></UpdateTooltip>
                            </template>
                          </td>
                        </tr>
                        <tr v-if="detail.po_number">
                          <td class="font-size-18 py-1 pr-4" width="200">
                            PO Number
                          </td>
                          <td colspan="2" class="font-weight-600 font-size-18">
                            {{ detail.po_number }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-size-18 py-1 pr-4" width="200">
                            Payable Amount
                          </td>
                          <td colspan="2" class="font-weight-600 font-size-18">
                            {{ formatMoney(detail.total - detail.paid_amount) }}
                          </td>
                        </tr>
                        <tr v-if="lodash.isEmpty(detail.payment_due) === false">
                          <td class="font-size-18 py-1" width="200">
                            Payment due
                          </td>
                          <td
                            class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                            v-on:dblclick="updateField('payment_due')"
                          >
                            <template v-if="updateDetailDialog.payment_due">
                              <v-select
                                :items="paymentDueList"
                                ref="reference"
                                v-model.trim="updateDetail.payment_due"
                                dense
                                filled
                                solo
                                flat
                                hide-details
                                :loading="particuleFieldLoading"
                                :disabled="particuleFieldLoading"
                                label="Payment due"
                                color="cyan"
                                item-text="text"
                                item-value="id"
                                item-color="cyan"
                                @keydown.esc="onEscParticularField"
                                @change="updateParticularField"
                              ></v-select>
                            </template>
                            <template v-else>
                              <span
                                :class="{
                                  'custom-bottom-border': canUpdateInvoice,
                                }"
                              >
                                <template v-if="detail.payment_due">{{
                                  detail.payment_due.text
                                }}</template>
                                <template v-else
                                  ><em class="text--secondary font-weight-500"
                                    >No Payment Due</em
                                  ></template
                                >
                              </span>
                              <UpdateTooltip
                                v-if="canUpdateInvoice"
                              ></UpdateTooltip>
                            </template>
                          </td>
                        </tr>
                        <tr class="custom-border-top">
                          <td
                            class="font-size-18 py-1 custom-border-right pr-4"
                            width="200"
                          >
                            Invoice date
                          </td>
                          <td
                            class="font-size-18 px-4 py-1 custom-border-right"
                            width="200"
                          >
                            Sent date
                          </td>
                          <td class="font-size-18 px-4 py-1" width="200">
                            Due date
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="200"
                            class="font-weight-600 font-size-18 py-1 pr-4 custom-border-right"
                            v-on:dblclick="updateField('invoice_date')"
                          >
                            <template v-if="updateDetailDialog.invoice_date">
                              <v-menu
                                v-model="invoiceDatePicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                @keydown.esc="onEscParticularField"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    ref="invoice_date"
                                    dense
                                    filled
                                    flat
                                    label="Invoice Date"
                                    hide-details
                                    solo
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :value="
                                      getFormattedDate(
                                        updateDetail.invoice_date
                                      )
                                    "
                                    color="cyan"
                                    :loading="particuleFieldLoading"
                                    :disabled="particuleFieldLoading"
                                    @keydown.esc="onEscParticularField"
                                    @keydown.enter="updateParticularField"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model.trim="updateDetail.invoice_date"
                                  color="cyan"
                                  @keydown.esc="onEscParticularField"
                                  v-on:input="updateParticularField"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="cyan"
                                    @click="invoiceDatePicker = false"
                                  >
                                    Cancel
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </template>
                            <template v-else>
                              <span
                                :class="{
                                  'custom-bottom-border': canUpdateInvoice,
                                }"
                              >
                                {{ formatDate(detail.invoice_date) }}
                              </span>
                              <UpdateTooltip
                                v-if="canUpdateInvoice"
                              ></UpdateTooltip>
                            </template>
                          </td>
                          <td
                            width="200"
                            class="font-weight-600 font-size-18 py-1 px-4 custom-border-right"
                            v-on:dblclick="updateField('sent_date')"
                          >
                            <template v-if="updateDetailDialog.sent_date">
                              <v-menu
                                v-model="sentDatePicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                @keydown.esc="onEscParticularField"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    ref="sent_date"
                                    dense
                                    filled
                                    flat
                                    label="Sent date"
                                    hide-details
                                    solo
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :value="
                                      getFormattedDate(updateDetail.sent_date)
                                    "
                                    color="cyan"
                                    :loading="particuleFieldLoading"
                                    :disabled="particuleFieldLoading"
                                    @keydown.esc="onEscParticularField"
                                    @keydown.enter="updateParticularField"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model.trim="updateDetail.sent_date"
                                  color="cyan"
                                  @keydown.esc="onEscParticularField"
                                  v-on:input="updateParticularField"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="cyan"
                                    @click="sentDatePicker = false"
                                  >
                                    Cancel
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </template>
                            <template v-else>
                              <span
                                :class="{
                                  'custom-bottom-border': canUpdateInvoice,
                                }"
                              >
                                <template v-if="detail.sent_date">{{
                                  formatDate(detail.sent_date)
                                }}</template>
                                <template v-else
                                  ><em class="text--secondary font-weight-500"
                                    >No Sent Date</em
                                  ></template
                                >
                              </span>
                              <UpdateTooltip
                                v-if="canUpdateInvoice"
                              ></UpdateTooltip>
                            </template>
                          </td>
                          <td
                            width="200"
                            class="font-weight-600 font-size-18 py-1 px-4"
                            v-on:dblclick="updateField('due_date')"
                          >
                            <template v-if="updateDetailDialog.due_date">
                              <v-menu
                                v-model="dueDatePicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                @keydown.esc="onEscParticularField"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    ref="due_date"
                                    dense
                                    filled
                                    flat
                                    label="Open till"
                                    hide-details
                                    solo
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :value="
                                      getFormattedDate(updateDetail.due_date)
                                    "
                                    color="cyan"
                                    :loading="particuleFieldLoading"
                                    :disabled="particuleFieldLoading"
                                    @keydown.esc="onEscParticularField"
                                    @keydown.enter="updateParticularField"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model.trim="updateDetail.due_date"
                                  color="cyan"
                                  @keydown.esc="onEscParticularField"
                                  v-on:input="updateParticularField"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="cyan"
                                    @click="dueDatePicker = false"
                                  >
                                    Cancel
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </template>
                            <template v-else>
                              <span
                                :class="{
                                  'custom-bottom-border': canUpdateInvoice,
                                }"
                              >
                                {{ formatDate(detail.due_date) }}
                              </span>
                              <UpdateTooltip
                                v-if="canUpdateInvoice"
                              ></UpdateTooltip>
                            </template>
                          </td>
                        </tr>
                      </table>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" class="mt-8">
              <v-tabs
                active-class="custom-tab-active"
                v-model="invoiceTab"
                background-color="transparent"
                color="cyan"
                class="custom-tab-transparent tab-sticky"
              >
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#other"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/invoice.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Overview
                </v-tab>

                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#payment"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/payment.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.payments_count"
                    bordered
                    color="orange darken-4"
                    :content="detail.payments_count"
                  >
                    {{ detail.payments_count > 1 ? "Payments" : "Payment" }}
                  </v-badge>
                  <template v-else> Payment </template>
                </v-tab>

                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.line_items_count"
                    bordered
                    color="orange darken-4"
                    :content="detail.line_items_count"
                  >
                    {{
                      detail.line_items_count > 1 ? "Line Item" : "Line Item"
                    }}
                  </v-badge>
                  <template v-else> Line Item </template>
                </v-tab>
                <v-tab
                  v-if="quotationId > 0"
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#quotation"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/quotation.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Quotation
                </v-tab>
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="invoiceTab">
                <v-tab-item value="other">
                  <PaymentTermsDetail
                    type="invoice"
                    :detail="detail"
                    :canUpdate="canUpdateInvoice"
                  ></PaymentTermsDetail>
                  <TermConditionDetail
                    type="invoice"
                    :detail="detail"
                    :canUpdate="canUpdateInvoice"
                  ></TermConditionDetail>
                  <InternalNoteAttachmentDetail
                    type="invoice"
                    :detail="detail"
                    :canUpdate="canUpdateInvoice"
                  ></InternalNoteAttachmentDetail>
                </v-tab-item>
                <v-tab-item value="payment">
                  <PaymentListingTemplate
                    :detail="detail"
                    type="invoice"
                  ></PaymentListingTemplate>
                </v-tab-item>
                <v-tab-item value="line-item">
                  <LineItemNew
                    show-detail
                    :can-update="canUpdateInvoice"
                    :loading="lineItemLoading"
                    :db-line-items="dbLineItems"
                    :discount-value="detail.discount_value"
                    :discount-value-type="detail.discount_value_type"
                    :apply-tax="detail.tax_applied"
                    :tax-value="detail.tax_value"
                    :adjustment-value="detail.adjustment"
                    v-on:update:line-item="updateLineItem($event)"
                    v-on:update:line-item-calculation="
                      updateLineItemCalculation($event)
                    "
                  ></LineItemNew>

                  <!-- <LineItemDetail
                    type="invoice"
                    :detail="detail"
                    :pageLoading="pageLoading"
                    v-on:load:parent="loadContent"
                    :canUpdate="canUpdateInvoice"
                  ></LineItemDetail> -->
                </v-tab-item>
                <v-tab-item v-if="quotationId > 0" value="quotation">
                  <InternalQuotationDetail
                    :quotation="quotationId"
                  ></InternalQuotationDetail>
                </v-tab-item>
                <v-tab-item value="history">
                  <InternalHistoryDetail
                    type="invoice"
                    :type_id="detail.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <CreatePaymentTemplate
        :detail="detail"
        :dialog="recordPaymentDialog"
        v-on:close="recordPaymentDialog = false"
        v-on:success="loadContent()"
      ></CreatePaymentTemplate>
    </template>

    <template v-slot:footer> </template>
  </DetailTemplate>
</template>

<script>
import DetailInvoiceMixin from "@/core/lib/invoice/detail.invoice.mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
// import LineItemDetail from "@/view/pages/partials/Detail/Line-Item-Detail.vue";
import TermConditionDetail from "@/view/pages/partials/Detail/Term-Condition-Detail.vue";
import PaymentTermsDetail from "@/view/pages/partials/Detail/Payment-Terms-Detail.vue";
import InternalNoteAttachmentDetail from "@/view/pages/partials/Detail/Internal-Note-Attachment-Detail.vue";
import InternalQuotationDetail from "@/view/pages/partials/Detail/Internal-Quotation-Detail.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import UpdateTooltip from "@/view/pages/partials/UpdateTooltip.vue";
import CreatePaymentTemplate from "@/view/pages/invoice/Create-Payment.vue";
import PaymentListingTemplate from "@/view/pages/partials/Detail/Payments.vue";
import LineItemNew from "@/view/pages/partials/Line-Item-New";

export default {
  mixins: [DetailInvoiceMixin],
  components: {
    LineItemNew,
    CreatePaymentTemplate,
    UpdateTooltip,
    DetailTemplate,
    PaymentTermsDetail,
    // LineItemDetail,
    TermConditionDetail,
    InternalNoteAttachmentDetail,
    InternalQuotationDetail,
    InternalHistoryDetail,
    PaymentListingTemplate,
  },
};
</script>
